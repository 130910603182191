<template>
   <ObiText class="obi-container" :class="classList" v-bind="attrs" v-on="$listeners"><slot /></ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiContainer',
   props: {
      fluid: {
         type: Boolean,
         default: false,
      },
      sm: {
         type: Boolean,
         default: false,
      },
      md: {
         type: Boolean,
         default: false,
      },
      lg: {
         type: Boolean,
         default: false,
      },
      xl: {
         type: Boolean,
         default: false,
      },
      xxl: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      classList() {
         let classList = [];

         if (this.fluid) {
            classList.push('container-fluid');
         }

         if (!this.fluid && !this.sm && !this.md && !this.lg && !this.xl && !this.xxl) {
            classList = ['container'];
         }

         return classList;
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-container {
}
</style>
